























import Vue, { PropType } from "vue";
import ActionButton from "./ActionButton.vue";
import PhoneInput from "./PhoneInput.vue";
import TextInput from "./TextInput.vue";

interface AccountParams {
  name: string;
  phoneNumber?: string;
  website?: string;
  customerEmail?: string;
}

export default Vue.extend({
  name: "AccountForm",
  components: {
    TextInput,
    PhoneInput,
    ActionButton
  },
  props: {
    type: { type: String, required: true },
    formValues: {
      type: Object as PropType<AccountParams>,
      default: () => ({} as AccountParams)
    },
    onSubmit: { type: Function, required: true }
  },
  data() {
    return {
      website: this.formValues?.website ?? "",
      name: this.formValues?.name ?? "",
      phoneNumber: this.formValues?.phoneNumber ?? "",
      customerEmail: this.formValues?.customerEmail ?? "",
      submitting: false,
      error: null as Error | null
    };
  },
  computed: {
    isCreating(): boolean {
      return this.type !== "Edit";
    },
    hasChanges(): boolean {
      return (
        this.formValues?.name !== this.name ||
        this.formValues?.phoneNumber !== this.phoneNumber ||
        this.formValues?.website !== this.website ||
        this.formValues?.customerEmail === this.customerEmail
      );
    }
  },
  methods: {
    async submitAccountForm(): Promise<void> {
      if (this.submitting === false) {
        this.submitting = true;
        this.error = null;
        try {
          const data: AccountParams = {
            name: this.name.trim(),
            website: this.website.trim(),
            phoneNumber: this.phoneNumber.trim(),
            customerEmail: this.customerEmail.trim()
          };
          if (!data.name) {
            throw new Error("You must provide a name for the new account.");
          }
          if (!data.phoneNumber) {
            throw new Error("You must provide a phone number for the new account.");
          }
          if (this.isCreating && !data.customerEmail) {
            throw new Error(
              "You must provide an email for this customer so we can associate that with their Stripe customer."
            );
          }
          await this.onSubmit(data);
        } catch (error) {
          console.error(error);
          this.error = error as Error;
        }
        this.submitting = false;
      }
    }
  }
});
