








































import Vue, { PropType } from "vue";
import { defaultAccountLimits } from "@/typings/defaults/accounts";

import ActionButton from "./ActionButton.vue";
import Checkbox from "./Checkbox.vue";
import NumberInput from "./NumberInput.vue";

export default Vue.extend({
  name: "AccountLimitsForm",
  components: {
    ActionButton,
    Checkbox,
    NumberInput
  },
  props: {
    type: { type: String, required: true },
    formValues: {
      type: Object as PropType<Account.Limits>,
      default: () => defaultAccountLimits()
    },
    onSubmit: {
      type: Function as PropType<(arg0: Account.Limits) => Promise<void>>,
      required: true
    }
  },
  data() {
    return {
      unlimitedSuperusers:
        this.formValues.unlimited_superusers || this.formValues.superusers === null,
      // unlimitedSuperusers: false,
      superusers: this.formValues.superusers ?? 1,
      unlimitedAdministrators:
        this.formValues.unlimited_administrators || this.formValues.administrators === null,
      // unlimitedAdministrators: false,
      administrators: this.formValues.administrators ?? 0,
      unlimitedReferrers:
        this.formValues.unlimited_referringUsers || this.formValues.referringUsers === null,
      // unlimitedReferrers: false,
      referringUsers: this.formValues.referringUsers ?? 5,
      submitting: false,
      error: null as Error | null
    };
  },
  computed: {
    hasChanges(): boolean {
      const superusers = this.unlimitedSuperusers ? null : this.superusers;
      const admins = this.unlimitedAdministrators ? null : this.administrators;
      const referrers = this.unlimitedReferrers ? null : this.referringUsers;
      return (
        superusers !== this.formValues.superusers ||
        admins !== this.formValues.administrators ||
        referrers !== this.formValues.referringUsers
      );
    }
  },
  watch: {
    superusers() {
      this.error = null;
    },
    administrators() {
      this.error = null;
    },
    referringUsers() {
      this.error = null;
    }
  },
  methods: {
    async submitAccountForm(): Promise<void> {
      if (this.submitting === false) {
        this.submitting = true;
        this.error = null;
        try {
          const superusers = this.superusers;
          const administrators = this.administrators;
          const referringUsers = this.referringUsers;
          const unlimited_superusers = this.unlimitedSuperusers;
          const unlimited_administrators = this.unlimitedAdministrators;
          const unlimited_referringUsers = this.unlimitedReferrers;
          await this.onSubmit({
            superusers,
            administrators,
            referringUsers,
            unlimited_superusers,
            unlimited_administrators,
            unlimited_referringUsers
          });
        } catch (error) {
          console.error(error);
          this.error = error as Error;
        }
        this.submitting = false;
      }
    }
  }
});
