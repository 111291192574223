

























import Vue, { PropType } from "vue";
import orderBy from "lodash/orderBy";
import { relativeToNow } from "@/filters";
import { Timestamp } from "@/firebase";

import NoteEditModal from "./NoteEditModal.vue";
import UserName from "./UserName.vue";

import EmailIcon from "../icons/Email.vue";
import MessageIcon from "../icons/MessageOutline.vue";
import PhoneIcon from "../icons/Phone.vue";
import VideoCallIcon from "../icons/VideoCall.vue";

export default Vue.extend({
  name: "Note",
  components: {
    NoteEditModal,
    UserName,
    EmailIcon,
    MessageIcon,
    PhoneIcon,
    VideoCallIcon
  },
  props: {
    note: { type: Object as PropType<Note & { _path: string }>, required: true }
  },
  data: () => ({
    open: false
  }),
  computed: {
    noteTimestamp(): string {
      if (this.note.timestamp && this.note.timestamp instanceof Timestamp) {
        return relativeToNow(this.note.timestamp.toDate());
      }
      return "soon";
    }
  },
  methods: {
    openModal(): void {
      this.open = true;
    },
    closeModal(): void {
      this.open = false;
    },
    orderLogEntries<T extends LogEntry>(entries: Record<string, T>): T[] {
      return orderBy(entries, "timestamp", "desc");
    },
    async editNote(noteUpdates: Partial<Note>): Promise<void> {
      await this.$store.direct.dispatch.editNote({
        path: this.note._path,
        noteUpdates
      });
      this.closeModal();
    }
  }
});
