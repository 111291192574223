


























































































































































































































import Vue from "vue";
import { db } from "@/firebase";

import AccountEditModal from "@/components/AccountEditModal.vue";
import ActionButton from "@/components/ActionButton.vue";
import LimitsEditModal from "@/components/LimitsEditModal.vue";
import MetadataBlurb from "@/components/MetadataBlurb.vue";
import Notes from "@/components/Notes.vue";

import { defaultAccountLimits, defaultAccountCounts } from "@/typings/defaults/accounts";
import { defaultKaijuInfo } from "@/typings/defaults/kaiju";

export default Vue.extend({
  name: "AccountDetails",
  components: {
    AccountEditModal,
    ActionButton,
    LimitsEditModal,
    MetadataBlurb,
    Notes
  },
  data: () => ({
    isCounting: false,
    editAccountModalIsOpen: false,
    editLimitsModalIsOpen: false,
    kaijuActionSubmitting: false,
    error: null as Error | null
  }),
  computed: {
    currentUserId(): string | null {
      return this.$store.direct.state.currentUser.uid;
    },
    accountId(): string | undefined {
      return this.$route.params.accountId;
    },
    account(): AccountStoreEntry | undefined {
      return this.accountId ? this.$store.direct.state.accounts.all[this.accountId] : undefined;
    },
    websiteText(): string | null {
      return (
        this.account?.website
          ?.replace(/^(https:\/\/)/, "")
          ?.replace(/^(http:\/\/)/, "")
          ?.replace(/^(www\.)/, "") ?? null
      );
    },
    accountPhoneNumber(): string | null {
      return this.account?.phoneNumber?.replace(/[\D]/g, "") ?? null;
    },
    limits(): Account.Limits {
      return this.account?.limits ?? defaultAccountLimits();
    },
    counts(): Account.Counts {
      return this.account?.counts ?? defaultAccountCounts();
    },
    kaijuInfo(): Kaiju.Info {
      return this.account?.kaijuInfo ?? defaultKaijuInfo();
    },
    totalRegisteredUsers(): number {
      return this.counts?.users ?? 0;
    },
    accountFirestoreRef(): DocumentReference | null {
      if (!this.account) return null;
      return db.collection("accounts").doc(this.accountId);
    }
  },
  methods: {
    async handleKaijuUpdate(
      status: "enroll" | "enable" | "disable" | "resume" | "pause"
    ): Promise<void> {
      if (this.kaijuActionSubmitting) return;
      this.kaijuActionSubmitting = true;
      const accountId = this.accountId ?? "";
      const userId = this.currentUserId ?? "";
      try {
        switch (status) {
          case "enroll":
            await this.$store.direct.dispatch.enrollInKaiju(accountId);
            break;
          case "enable":
            await this.$store.direct.dispatch.enableKaiju({ accountId, userId });
            break;
          case "disable":
            await this.$store.direct.dispatch.disableKaiju({ accountId, userId });
            break;
          case "resume":
            await this.$store.direct.dispatch.resumeKaiju({ accountId, userId });
            break;
          case "pause":
            await this.$store.direct.dispatch.pauseKaiju({ accountId, userId });
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
        console.log(error);
        if (error instanceof Error) {
          this.error = error;
        } else {
          this.error = new Error(error);
        }
      } finally {
        this.kaijuActionSubmitting = false;
      }
    },
    async enrollInKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("enroll");
    },
    async enableKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("enable");
    },
    async disableKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("disable");
    },
    async resumeKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("resume");
    },
    async pauseKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("pause");
    }
  }
});
