













import Vue, { PropType } from "vue";

import TextInput from "@/components/TextInput.vue";

export default Vue.extend({
  name: "PhoneInput",
  components: {
    TextInput
  },
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "" },
    placeholder: { type: String as PropType<string | null>, default: null },
    autocomplete: { type: String as PropType<string | null>, default: null },
    dataTest: { type: String as PropType<string | null>, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false }
  },
  data() {
    return {
      phone: this.value || ""
    };
  },
  computed: {
    normalizedPhoneNumber(): string {
      return this.phone.replace(/\D/g, "");
    },
    formattedPhoneNumber(): string {
      const match = this.normalizedPhoneNumber.match(/^(1|)?(\d{3})?(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
      }
      return this.normalizedPhoneNumber;
    }
  },
  watch: {
    value(newPhoneNumberValue: string) {
      this.phone = newPhoneNumberValue;
    },
    normalizedPhoneNumber(normalizedPhoneNumber: string) {
      const digits = normalizedPhoneNumber.length;
      if (digits === 10 || digits === 11) {
        this.$emit("input", this.formattedPhoneNumber);
      } else {
        this.$emit("input", this.phone);
      }
    }
  },
  methods: {
    formatPhoneNumber(): string {
      const cleaned = this.normalizedPhoneNumber;
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
      }
      return cleaned;
    }
  }
});
