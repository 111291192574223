







import Vue, { PropType } from "vue";
import { db } from "@/firebase";

import AccountForm from "./AccountForm.vue";
import Modal from "./Modal.vue";

export default Vue.extend({
  name: "AccountEditModal",
  components: {
    AccountForm,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function, required: true },
    account: { type: Object as PropType<Account.Info>, required: true }
  },
  computed: {
    formValues(): Partial<Account.Info> {
      return {
        name: this.account.name,
        website: this.account.website,
        phoneNumber: this.account.phoneNumber
      };
    }
  },
  methods: {
    async editAccount({ name, website, phoneNumber }: Partial<Account.Info>) {
      await db.collection("accounts").doc(this.account.id).update({ name, website, phoneNumber });
      this.closeModal();
    }
  }
});
