









import Vue from "vue";
export default Vue.extend({
  name: "MetadataBlurb",
  props: {
    heading: { type: String, required: true },
    flow: { type: String, default: "horizontal" }
  },
  computed: {
    isVertical(): boolean {
      return this.flow === "vertical";
    }
  }
});
