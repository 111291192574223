




































import Vue from "vue";

import ActionButton from "./ActionButton.vue";

export default Vue.extend({
  name: "NumberInput",
  components: {
    ActionButton
  },
  props: {
    value: { type: Number, default: 0 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: Number.MAX_SAFE_INTEGER },
    nanDefault: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({
    inputValue: "0"
  }),
  computed: {
    numberValue() {
      return Number.parseInt(this.inputValue, 10);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue: number) {
        this.inputValue = `${newValue}`;
      }
    }
  },
  methods: {
    increment(): void {
      if (this.value < this.max) {
        this.inputValue = `${this.value + 1}`;
        this.emitInputChangeEvent();
      }
    },
    decrement(): void {
      if (this.value > this.min) {
        this.inputValue = `${this.value - 1}`;
        this.emitInputChangeEvent();
      }
    },
    editValue(): void {
      if (isNaN(this.numberValue)) {
        this.inputValue = `${this.nanDefault}`;
      } else if (this.numberValue < this.min) {
        this.inputValue = `${this.min}`;
      } else if (this.numberValue > this.max) {
        this.inputValue = `${this.max}`;
      }
      this.emitInputChangeEvent();
    },
    emitInputChangeEvent(): void {
      this.$emit("input", this.numberValue);
    }
  }
});
