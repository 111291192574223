


















import Vue from "vue";

export default Vue.extend({
  name: "Checkbox",
  props: {
    value: { type: Boolean, required: true },
    id: { type: String, default: "DEFAULT_PLEASE_CHANGE" },
    small: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      checkboxValue: this.value
    };
  },
  watch: {
    value(newValue: boolean, oldValue: boolean) {
      if (newValue !== oldValue) {
        const { value } = this;
        this.checkboxValue = value;
      }
    },
    checkboxValue(newCheckboxValue: boolean) {
      this.$emit("input", newCheckboxValue);
    }
  }
});
