
















import Vue, { PropType, VueConstructor } from "vue";
import { Radio as VueCheckboxRadio } from "vue-checkbox-radio";

export default Vue.extend({
  name: "Radio",
  components: {
    VueCheckboxRadio: VueCheckboxRadio as VueConstructor
  },
  props: {
    id: { type: String as PropType<string | null>, default: null },
    name: { type: String as PropType<string | null>, default: null },
    modelValue: { type: String as PropType<string | null>, default: null },
    value: { type: String as PropType<string | null>, default: null },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      radioValue: this.value
    };
  },
  watch: {
    // A new value from the parent component
    value(newValue: string | null, oldValue: string | null) {
      if (newValue !== oldValue) {
        this.radioValue = newValue;
      }
    },
    radioValue(newRadioValue: string | null) {
      if (newRadioValue === this.modelValue) {
        this.$emit("input", newRadioValue);
      }
    }
  }
});
