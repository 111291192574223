










import Vue, { PropType } from "vue";

import ActionButton from "./ActionButton.vue";
import Modal from "./Modal.vue";
import NoteForm from "./NoteForm.vue";

import AddNoteIcon from "@/icons/AddNote.vue";

export default Vue.extend({
  name: "NoteAddModalButton",
  components: {
    ActionButton,
    NoteForm,
    Modal,
    AddNoteIcon
  },
  props: {
    onAddLogEntry: {
      type: Function as PropType<(logEntry: LogEntry) => Promise<void>>,
      required: true
    }
  },
  data: () => ({
    open: false
  }),
  methods: {
    openModal(): void {
      this.open = true;
    },
    closeModal(): void {
      this.open = false;
    },
    async addLogEntry(logEntry: LogEntry): Promise<void> {
      await this.onAddLogEntry(logEntry);
      this.closeModal();
    }
  }
});
