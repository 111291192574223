
























import Vue, { PropOptions } from "vue";
import { orUnlimited, toCompactTimestamp } from "@/filters";
import UserName from "./UserName.vue";

export default Vue.extend({
  name: "Limits",
  components: {
    UserName
  },
  props: {
    accountId: { type: String, required: true },
    type: { type: String, default: "limits" } as PropOptions<
      "limits" | "overrideLimits" | "planLimits"
    >
  },
  computed: {
    limits(): Account.Limits | undefined {
      const account = this.$store.direct.state.accounts.all[this.accountId];
      return account?.[this.type];
    },
    limitDescription(): string {
      switch (this.type) {
        case "overrideLimits":
          return "Override Limits";
        case "planLimits":
          return "Plan Limits";
        case "limits":
        default:
          return "Limits";
      }
    }
  },
  mounted() {
    void this.$store.direct.dispatch.watchLimits({
      accountId: this.accountId,
      limitType: this.type
    });
  },
  methods: {
    orUnlimited,
    toCompactTimestamp
  }
});
