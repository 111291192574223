







import Vue, { PropType } from "vue";

import Modal from "./Modal.vue";
import NoteForm from "./NoteForm.vue";

interface FormValues {
  text?: string;
  title?: string;
  contactType?: string;
}

export default Vue.extend({
  name: "NoteEditModal",
  components: {
    NoteForm,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function as PropType<() => void>, required: true },
    note: { type: Object as PropType<FormValues>, required: true },
    editNote: { type: Function as PropType<(arg0: FormValues) => Promise<void>>, required: true }
  },
  computed: {
    formValues(): FormValues {
      console.log("NoteEditModal formValues", this.note);
      return {
        title: this.note.title,
        text: this.note.text,
        contactType: this.note.contactType
      };
    }
  }
});
