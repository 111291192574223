




























import Vue, { PropType } from "vue";

import ErrorNotice from "./ErrorNotice.vue";
import TextInput from "./TextInput.vue";
import TextAreaInput from "./TextAreaInput.vue";
import ActionButton from "./ActionButton.vue";
import Radio from "./Radio.vue";

export interface FormValues {
  text?: string;
  title?: string;
  contactType?: string;
}

export default Vue.extend({
  name: "NoteForm",
  components: {
    ErrorNotice,
    TextInput,
    TextAreaInput,
    ActionButton,
    Radio
  },
  props: {
    formValues: { type: Object as PropType<FormValues>, default: () => ({} as FormValues) },
    onSubmit: { type: Function as PropType<(arg0: FormValues) => Promise<void>>, required: true }
  },
  data() {
    return {
      text: this.formValues?.text || "",
      title: this.formValues?.title || "",
      contactType: this.formValues?.contactType || "",
      error: null as Error | null,
      submitting: false,
      typeOfContact: ["Phone Call", "Text Message", "Email", "Video Call"]
    };
  },
  methods: {
    async submitLogEntryForm(): Promise<void> {
      if (this.submitting === false) {
        this.submitting = true;
        this.error = null;
        try {
          const valuesToSubmit: Required<FormValues> = {
            title: this.title.trim(),
            text: this.text.trim(),
            contactType: this.contactType.trim()
          };
          if (!valuesToSubmit.title && !valuesToSubmit.text) {
            throw new Error("You must provide a title or text for your log entry.");
          }
          await this.onSubmit(valuesToSubmit);
        } catch (error) {
          console.error(error);
          if (error instanceof Error) {
            this.error = error;
          } else {
            this.error = new Error(error);
          }
        }
        this.submitting = false;
      }
    }
  }
});
