




















import Vue, { PropType } from "vue";
import orderBy from "lodash/orderBy";

import NoteAddModalButton from "@/components/NoteAddModalButton.vue";
import ListFirestore from "@/components/ListFirestore.vue";
import Note from "@/components/Note.vue";

export default Vue.extend({
  name: "Notes",
  components: {
    NoteAddModalButton,
    ListFirestore,
    Note
  },
  props: {
    entityFirestoreRef: {
      type: Object as PropType<DocumentReference>,
      required: true
    },
    autoload: { type: Boolean, default: false }
  },
  methods: {
    onDataLoaded(): void {
      this.$emit("loaded", this.$options.name);
    },
    getLogEntries(): FirestoreQuery {
      return this.entityFirestoreRef.collection("interactions").orderBy("timestamp", "desc");
    },
    orderLogEntries<T extends LogEntry>(entries: Record<string, T>): T[] {
      return orderBy(entries, "timestamp", "desc");
    },
    async addNote(newNote: Note): Promise<void> {
      await this.$store.direct.dispatch.addNote({
        firestoreRef: this.entityFirestoreRef,
        newNote
      });
    }
  }
});
