













import Vue, { PropType } from "vue";
import { defaultAccountLimits } from "@/typings/defaults/accounts";
import { callableFunctions } from "@/firebase";

import AccountLimitsForm from "./AccountLimitsForm.vue";
import Limits from "./Limits.vue";
import Modal from "./Modal.vue";

export default Vue.extend({
  name: "LimitsEditModal",
  components: {
    AccountLimitsForm,
    Limits,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function as PropType<() => void>, required: true },
    account: { type: Object as PropType<AccountStoreEntry>, required: true }
  },
  computed: {
    formValues(): Account.Limits {
      return this.account?.limits ?? defaultAccountLimits();
    }
  },
  watch: {
    open: {
      immediate: true,
      handler(open: boolean) {
        if (open) {
          void this.$store.direct.dispatch.watchAccountOverrideLimits(this.account.id);
          void this.$store.direct.dispatch.watchAccountPlanLimits(this.account.id);
        }
      }
    }
  },
  methods: {
    async editAccountLimits({
      superusers,
      administrators,
      referringUsers,
      unlimited_superusers,
      unlimited_administrators,
      unlimited_referringUsers
    }: Account.Limits): Promise<void> {
      await callableFunctions.modifyAccountLimits({
        superusers,
        administrators,
        referringUsers,
        unlimited_superusers,
        unlimited_administrators,
        unlimited_referringUsers,
        accountId: this.account.id
      });
      this.closeModal();
    }
  }
});
