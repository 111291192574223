




















import Vue, { PropType } from "vue";

import XIcon from "@/icons/X.vue";

export default Vue.extend({
  name: "Modal",
  components: {
    XIcon
  },
  props: {
    open: { type: Boolean, required: true },
    closeModal: { type: Function as PropType<() => void>, required: true },
    modalClass: { type: String, default: "" }
  },
  beforeDestroy() {
    this.closeModal();
  }
});
